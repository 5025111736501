.show-more {
    position: absolute;
    top: 45%;
    right:0;
    height: 100px;
    width: 90px;
}

@media screen and (min-width: 768px){
    .show-more {
       
        top: 46%;
      
       
    }
}


.show-more-animation {
    opacity: 1;
    transform: translateX(40%);
    animation: 3s ease-out 0s 1 slideLeft;

    a{

        .show-more-text{
            width: 2vw;
            min-width:20px;
            max-width: 90px;
           
            filter: drop-shadow(3px 3px 5px rgb(0 0 0 /0.4));
        }
          
        .show-more-arrow{
            position: absolute;
            top: 45%;
            right: 75px;
            width: 3vw;
            // margin-right: 10px;
            margin-top: 8px;
            min-width:30px;
            max-width: 45px;
            transition: all .25s ease;
            filter: drop-shadow(3px 3px 5px rgb(0 0 0 /0.4));
        }
    }
}

.arrow-rotate{
    transform: rotate(180deg);
    transition: all .25s ease;
    animation: .25s ease-out 0s 1 arrowRotateOut;
}

.arrow-pulse{
    animation: 2s ease-out 1s infinite pulse;
}


@keyframes slideLeft {
    0%{
        opacity: 0;
        transform: translateX(200%);
    }
    50%{
        opacity: 0;
        transform: translateX(200%);
    }
    100%{
        opacity: 1;
        transform: translateX(40%);
    }
}

@keyframes arrowRotateOut {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(180deg);
    }
}

@keyframes pulse {
    0%{
        transform: scale(1);
        opacity: .5;
    }
    
    50%{
        transform: scale(1.2);
        opacity: 1;
    }
    
    
    100%{
        transform: scale(1);
        opacity: .5;
    }
}