.ytbs-main{
    
   
  
    overflow: hidden;
    
    width: 100vw;
}

.ytbs-inner-container{
    position: absolute;
    top: 45%;
    left: 20%;
   
    transform: scale(150%) ;
    transition: all 1s ease;
}

.ybts-exit{
    transform: translateX(90%) scale(150%);
    // display:none;
    opacity: 0;
}
.ytbs-animation {
    opacity: 1;
    transform: translateX(0);
    animation: 4s ease-out 0s 1 slideRightFadeIn;

    object{
        width: 50vw;
        max-width: 500px;
        filter: drop-shadow(3px 3px 5px rgb(0 0 0 /0.4));
    }
}

@media screen and (min-width: 576px ) {
    .ytbs-main{
    
   
  
        overflow: hidden;
        
        width: 100vw;
    }
    
    .ytbs-inner-container{
        position: absolute;
        top: 46%;
        left: 20%;
       
        transform: scale(120%) ;
        transition: all 1s ease-out;
    }
    
    .ybts-exit{
        transform: scale(120%) translateX(70%);
        opacity: 0;
    }
    .ytbs-animation {
        opacity: 1;
        transform: translateX(0);
        animation: 4s ease-out 0s 1 slideRightFadeIn;
    
        object{
            width: 50vw;
            max-width: 500px;
            filter: drop-shadow(3px 3px 5px rgb(0 0 0 /0.4));
        }
    }
    
}

@keyframes slideRightFadeIn {
    0%{
        opacity: 0;
        transform: translateX(-100%);
    }
    60%{
        opacity: 0;
        transform: translateX(-100%);
    }
    100%{
        opacity: 1;
        transform: translateX(0);
    }
}