.logo-sled-container{
    position: absolute;
    top:50%;
    // left:50%;
    
    transform: translateY(-40%) translateX(-200%);
    // background-color: aqua;
    height: 70vh;
    width: 100vw;
    transition: all 1s ease;
    
}
.sled-enter {
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%) translateX(0);
    // animation: 1.5s ease-out 0s 1 SledSlideIn;
}

.logo-sled-link-div{
  
    transform: scale(.9);
    transition: all 1s ease;

}
.logo-sled-link-div:hover{
    transform: scale(1.1);
}

.logo-sled-link-out{
    transform: translateX(-300%);
}
.logo-sled-link {
    max-width:290px;
}

@media screen and (min-width: 500px) {
    .logo-sled-container{
        position: absolute;
        top:50%;
        left:20%;
        transform: translateY(-40%) translateX(-200%);
        // background-color: aqua;
        height: 70vh;
        width: 300px;
        transition: all 1s ease;
        
    }
    .sled-enter {
        transform: translateY(-40%) translateX(-20%);
        // animation: 1.5s ease-out 0s 1 SledSlideIn;
    }
    
    .logo-sled-link-div{
        margin-top:1rem;
        margin-bottom:2rem;
        transition: all 1s ease;
    }
    
    .logo-sled-link-out{
        transform: translateX(-300%);
    }
    // .logo-sled-link {
    //     width:310px;
    // }
    
    
}


@media screen and (min-width: 768px) {
    .logo-sled-container{
        position: absolute;
        top:45%;
        left:20%;
        transform: translateY(-40%) translateX(-200%);
        // background-color: aqua;
        height: auto;
        width: auto;
        transition: all 1s ease;
        
    }
    .sled-enter {
        transform: translateY(-40%) translateX(0%);
        // animation: 1.5s ease-out 0s 1 SledSlideIn;
    }
    .logo-sled-link-div{
        margin-top:1rem;
        margin-bottom:1rem;
    }
    .logo-sled-link {
        
    }
    
}


@keyframes SledSlideIn {
    0%{
        transform: translateY(-40%) translateX(-200%);
    }
    50%{
        transform: translateY(-40%) translateX(-200%);
    }
    100%{
        transform: translateY(-40%) translateX(0);
    }
}
body{
    overflow: hidden;
}