.top-services-main{
    position: absolute;
    top: 18%;
    left: 25%;
    transform: scale(150%);

}

.top-services-animation {
    opacity: .08;
    transform: translateX(0);
    background-blend-mode: multiply;
    animation: 6s ease-out 0s 1 FadeIn;

    object{
        width: 50vw;
        
        height: 20%;
        // filter: drop-shadow(3px 3px 5px rgb(0 0 0 /0.4));
    }
}

@media screen and (min-weight: 768px ) {
    
}

@keyframes FadeIn {
    0%{
        opacity: 0;
       
    }
   50%{
    opacity: 0
   }
    100%{
        opacity: .08;
       
    }
}