.main-logo{
    position: absolute;
    top: 5%;
    left: -2.5%;
    width: 100%;
    display: flex;
    justify-content: center;

}

.main-logo-animation {
    
    opacity: 1;
    // transform: translateY(0);
    animation: 1s ease-out 0s 1 mainLogoAnimation;

    object{
       
        max-width: 350px;
        min-width: 100px;
        filter: drop-shadow(3px 3px 5px rgb(0 0 0 /0.6));
    }
}
@media screen and (min-width:500px) {
    .main-logo{
        width: auto;
        position: absolute;
        top: 3%;
        left: 10%;
        display:block;
    
    }
}
@media screen and (min-width:768px) {
   

    .main-logo-animation {
    
        opacity: 1;
        // transform: translateY(0);
        animation: 2s ease-out 0s 1 mainLogoAnimation;
    
        object{
           
            max-width: 400px;
            min-width: 100px;
            filter: drop-shadow(3px 3px 5px rgb(0 0 0 /0.6));
        }
    }
}

@keyframes mainLogoAnimation {
    0%{
        opacity: 0;
        transform: translateY(-100%);
    }
    50%{
        opacity: 0;
        transform: translateY(-50%);
    }
   
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}