
.full-gradient {
    position: absolute;
    top:0;
    width: 100vw;
    height: 100vh;
    background: rgb(0,44,83);
background: linear-gradient(90deg, rgba(0,44,83,0) 0%, rgba(0,44,83,0.702140231092437) 45%, rgba(0,44,83,0) 89%);


}
