.bottom-wedge-main {
    height: 80vh;
    transform: skewY(14deg) translateY(65%);
    background: rgb(0,93,150);
    background: radial-gradient(circle at top, rgba(0,93,150,1) 17%, rgba(0,44,83,1) 100%);
    box-shadow: 0px 8px 12px rgba( 0,0,0,.4);
    z-index: -2;
    animation: 2s ease-out 0s 1 slideInFromBottom;
    transition: all 1s ease;
}

.bottom-exit {
    transform: skewY(14deg) translateY(200%);
}


@keyframes slideInFromBottom {
    0% {
        transform: skewY(14deg) translateY(100%);
    }
    100%{
        transform: skewY(14deg) translateY(65%);
    }
}

