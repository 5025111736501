$primary-color: #0c79ba;
$primary-accent: #23adce;
$secondary-color: #f28323;
// $secondary-color: #1c1c1c;

$info-color: #666666;
$info-dark: #c4c4c4;

$background-light-grey : #e9e5e5;
$border-light-grey : #efefef;
$text-light: #ffffff;

:root{
  --app-height-calc: 100vh;
}
:export {
  primaryColor: $primary-color;
  primaryAccentColor: $primary-accent;
  secondaryColor: $secondary-color;

}

// reset everything
$theme-colors: (
  "primary": $primary-color,
  "primaryAccent": $primary-accent,
  "secondary": $secondary-color,
 
  
);

* {
    font-family: "Prompt", sans-serif;
    margin: 0;
    padding: 0;
    letter-spacing: 0.05rem;
  }
  
body {
  height: var(--app-height-calc);
}