@use "../core/main";

.orange-section {
    background-color: main.$secondary-color;
    position: absolute;
    top: 0;
    z-index: -1;
    height: 100vh;
    width: 100vw;

    animation: 3s ease 0s 1 fadeIn;
}

.orange-section-image {
    position: absolute;
    top: 0;
    right: 0;
    background-color: main.$secondary-color;
    background-image: url("../../../public/img/motherboardOrange.jpg");
    background-position: 40% 0%;
    background-size: cover;
    background-blend-mode: multiply;
    height: 100vh;
    width: 60vw;
    opacity: .5;
    mask-image: linear-gradient(to right, transparent 0%, black 100%);

    animation: 5s ease 0s 1 moveLeft;
}

@keyframes fadeIn {
    0%{
        background-color: black;
    }
    100%{
        background-color: main.$secondary-color;
    }
}

@keyframes moveLeft {
    0%{
        transform: translateX(100%);
    }
    10%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(0);
    }
}