.top-wedge-main {
    height: 90vh;
    transform: skewY(-14deg) translateY(-130%);
    background: rgb(0,93,150);
    background: radial-gradient(circle at bottom, rgba(0,93,150,1) 17%, rgba(0,44,83,1) 100%);
    box-shadow: 0px 8px 12px rgba( 0,0,0,.4);
    
    animation: 1s ease-out 0s 1 slideInFromTop;
    transition: all 1s ease;
}

.top-exit {
    transform: skewY(-14deg) translateY(-180%);
}


@media screen and (min-width: 400px) {
    
}


@keyframes slideInFromTop {
    0% {
        transform: skewY(-14deg) translateY(-180%);
    }
    100%{
        transform: skewY(-14deg) translateY(-130%);
    }
}
